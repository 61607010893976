@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";

$ft_bg:#F2F2F2;
$ft_black:#333333;
$ft_gray:#AAAAAA;
$ft_light_gray:#EEEEEE;
$ft_blue:$blue1;
$ft_box_border:#DDDDDD;
$ft_box_radius:5px;

$ft_title1:24px;
$ft_title2:18px;
$ft_title3:16px;
$ft_title4:14px;
$ft_title5:12px;
$ft_title6:9px;

$ft_normal_text: #666666;

$ft_title_weight:400;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f4f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// 页面样式
.ft-top-nav
{
  box-shadow: 0 0 1px 0px rgba(0,0,0,0.3), 0 0 6px 2px rgba(0,0,0,0.15);

  height:60px;
  background: rgba(255,255,255,0.99);
  position: fixed;
  width:100%;
  top:0px;
  z-index: 1;

  .container
  {
    height:100%;
    // background-color: pink;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .logo-box
    {
      display: flex;
      font-size: 1.2rem;
      align-items: center;
      color:#102D3B;
      cursor: pointer;
      img
      {
        height:60px;
        width: auto;
        cursor: pointer;
        // margin-right: .5rem;
      }
      
    }

    .avatar-name
    {
      font-size: $ft_title4;
      color:$ft_gray;
      margin-left:10px;
    }

    .avatar-icon
    {
        max-width: 32px;
        max-height: 32px;
        border-radius: 100%;
    }

    .top-nav-menu
    {
      display: flex;
      > li
      {
        display: flex;
        font-size: 18px;
        
        a
        {
          position: relative;
          display: flex;
          height:60px;
          text-decoration: none;
          border-bottom: 5px solid transparent;
          align-items: center;
          color:$gray1;
          // transition: all .3s;

          &::after
          {
            content:"";
            display: block;
            height: 8px;
            width: 0;
            position: absolute;
            bottom: -5px;
            background: $blue1;
            opacity: 1;
            // z-index: -999;
            transition: all .3s;
          }
          &.active
          {
            color:$blue1;
            &::after
            {
              width:100%;
              position: absolute;
              bottom: -5px;
              opacity: 1;
            }
          }
          &:hover
          {
            color:$blue2;
            &::after
            {
              width:100%;
            }
            // border-bottom: 5px solid $gray5;
            
          }
          
        }
        &:not(:last-child)
        {
          margin-right: 24px;
        }
      }
    }

    > *
    {
      display: flex;
      align-items: center;
    }


  }
  
  
}

.roadmap-list
{
  background-color: white;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  .container
  {
    text-align: center;
  }
  .roadmap-card
  {
    display: inline-flex;
    width:300px;
    height:290px;
    margin: 15px;
    padding:0px;
    flex-direction: column;
    .cover
    {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      img
      {
        max-width: 100%;
      }
    }
    .text
    {
      height:130px;
      padding:10px;
      overflow: hidden;
      font-size: 14px;
      line-height: 1.6;
      text-align: left;
      color: #666666;
      .h1
      {
        font-size: 16px;
        color:black;
      }
    }
    
  }
}

.cart-item
{
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: white;
  padding: 20px;
  font-size: 18px;

  &:not(:last-child)
  {
    border-bottom: 1px solid $ft_light_gray;
  }

  &:first-child
  {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .title
  {
    flex:5;
    cursor: pointer;
  }

  .price
  {
    flex:1;
    text-align: right;
  }

  .actionbar
  {
    margin-left: 10px;
  }
}

.flex-right
{
  display: flex;
  justify-content: flex-end;
}

.footer-box
{
  padding: 20px;
  text-align: center;
  color:$ft_gray;
  a
  {
    color:$ft_gray;
    &:hover
    {
      text-decoration: underline;
    }  
  }
}

// 组件css

.ft-top-notice
{
  z-index: 2;
  background-color: rgb(255, 196, 86);
  height:60px;

  position: fixed;
  bottom: 0px;
  width:100%;

  .closebtn
  {
    position: absolute;
    right: 10px;
    top:15px;
  }

  .container
  {
    height:100%;
  }
  
}


.ft-hero-div
{
  
  margin-top: 100px;
  margin-bottom:40px;
  max-height:400px;
  // background-color: bisque;
  .container
  {
    height:100%;
    .hero-box
    {
      display: flex;
      flex-direction: row;
      border-radius: 10px;
      height:100%;
      cursor: pointer;
      img
      {
        border-radius: 10px;
        width:100%;
        height:auto;
      }
    }
    
  }
}

.ft-bg-blue
{
  background-color: #069EFC;
}

.ft-bg-navy
{
  background-color: #435175;
}

.ft-bg-red
{
  background-color: #D14657;
}

.ft-bg-yellow
{
  background-color:#F7B03F;
}

.ft-bg-green
{
  background-color:#2A6049;
}

.cate-tab
{
  height:60px;
  background-color: rgba(250, 250, 250 , 0.95);
  display: flex;
  align-items: center;
  ul
  {
    height: 100%;
    padding:0px;
    display: flex;
    li
    {
      font-size: 18px;
      display: inline-block;
      height: 100%;
      &:not(:last-child)
      {
        margin-right: 50px;
      }
      a
      {
        position: relative;
        display: flex;
        height:60px;
        align-items: center;
        text-decoration: none;
        border-bottom: 5px solid transparent;
        color:$gray3;

        &::after
        {
          content:"";
          display: block;
          height: 8px;
          width: 0;
          position: absolute;
          bottom: -5px;
          background-color: $blue1;
          opacity: 1;
          // z-index: -999;
          transition: all .3s;
          
        }
        &.active
        {
          color:$blue1;
          // border-bottom: 5px solid $blue1;
          &::after
          {
            width:100%;
            position: absolute;
            bottom: -5px;
            opacity: 1;
          }
        }
        &:hover
        {
          color:$blue2;
          &::after
          {
            background-color:$blue2;
            width:100%;
            // color:red;
          }
          // border-bottom: 5px solid $gray5;
          
        }
      }
    }
  }
}

.maximg
{
  img
  {
    max-width: 100%;
  }
}

.cate-course-list
{
  background-color: white;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  .container
  {
    text-align: center;
  }
  .course-card
  {
    display: inline-flex;
    width:300px;
    height:290px;
    margin: 15px;
    padding:0px;
    flex-direction: column;
    .cover
    {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      img
      {
        max-width: 100%;
      }

    }
    .content
    {
      height:90px;
      padding:10px;
      overflow: hidden;
      text-align: left;
      .title
      {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
      }
      .subtitle
      {
        font-size: 14px;
        line-height: 24px;
        color:#000;
      }
    }
    .actionbar
    {
      position: relative;
      bottom: 0px;
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid #eee;
      .left,.right
      {
        height: 100%;
        display: flex;
        align-items: center;
        // background-color: red;
        padding:10px;
      }
    }
  }
}

.overbox
{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .centerbox
    {
        max-width:400px;
        min-width:400px;
        min-height:100px;
        background-color: white;
        border-radius: 5px;
        padding:20px;
    }
}

.section-qr
{
    position: relative;
    .close-button
    {
        position: absolute;
        right:-30px;
        top:-30px;
        cursor: pointer;
        z-index:999;

        svg
        {
            fill:white;
            color:white;
        }
    }
}

.course-box
{
  min-height: 200px;
  display: flex;
  color:white;

  .title
  {
    font-size: 24px;
    font-weight: bold;
  }
  .subtitle
  {
    margin-top: 15px;
    font-size: 18px;
  }

  .desp
  {
    margin-top: 30px;
    line-height: 1.8;
    max-width: 600px;
  }
  .content-box
  {
    flex:1;
    .container
    {
      display: flex;
      flex-direction: row;
      .right
      {
        width: 200px;
        .author-info
        {
          margin-top: 20px;
          margin-bottom: 40px;
          padding:20px;
          border-radius: 5px;
          .avatar img
          {
            max-width: 120px;
            max-height: 120px;
            border-radius: 100%;
            box-shadow: 0 2px 4px rgba(0,0,0,.16);
            &:hover
            {
              box-shadow: 0 8px 16px rgba(0,0,0,.16);
              // margin-left: 5px;
              transform:scale(1.02);
            }

          }
          .author-title
          {
            text-align: center;
            margin-bottom: 10px;
            margin-top: 10px;
            font-size: 18px;
          }
          .author-desp
          {
            line-height: 1.8;
          }
        }
      }
      .left
      {
        flex:1;
        margin-top: 10px;
        .catebutton
        {
          margin-bottom: 10px;
        }
      }
    }
  }
  .actionbar
  {
    // height:40px;
    margin-top: 30px;
    > *
    {
      &:not(:last-child)
      {
        margin-right: 10px;
      }
      margin-bottom: 10px;
    }

  }
  
  
}

.course-index
{
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  .chapter
  {
    &:not(:first-child)
    {
      margin-top: 20px;
    }
    .title
    {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding:10px;
      border-radius: 10px;
      background-color: rgba(0,0,0,0.05);
      font-size: 20px;
      font-weight: 500;
      .text
      { 
        padding-left:10px;
        flex:1; 
      }
      .action
      { 
        width:50px;
        padding-right:10px; 
      }
    }
  }
  .section-list
  {
    display: flex;
    flex-direction: column;
    padding:0px;
    > li
    {
      display: flex;
      padding:10px;
      border-bottom: 1px solid #eee;
      font-size: 18px;
      border-radius: 5px;
      cursor: pointer;

      &:hover
      {
        background-color: rgba(0,0,0,0.05);
        color:#435175;
      }
      &.active
      {
        background-color: #435175;
        color: white;
      }

    }
  }
}

.page-box
{
  margin-top: 60px;
}

.section-top-title
{
  color: white;
  font-size: $ft_title2;
  padding:10px;
}

.section-video > *
{
  background-color: black;
  max-width: 100%;
  max-height: calc( 100vh - 70px );
}

.section-markdown
{
  margin-top: 10px;
  font-size: $ft_title3;
  padding:10px;
}

.ft-countdown-box
{
  width: 300px;
  height: 300px;
  padding:20px;
  background-color: rgba(255,255,255,0.6);
  border-radius: 5px;
  position: fixed;
  left:calc( 50% - 150px );
  top:20vh;
  text-align: center;
  .spinner
  {
    padding:10px;
    margin-top: 50px;
  }

}

.ft-notop
{
  margin-top: 60px;
  padding-top:20px;
  padding-bottom:40px;
  .hero-box
  {
    cursor: default;
  }
}

.notice-box
{
  background-color: white;
  padding: 20px;
  font-size: 18px;
}

.top50
{
  margin-top: 50px;
}

.right20
{
  margin-right: 20px;
}

.bottom20
{
  margin-bottom: 20px;
}

.ft-post-page
{
  margin-left:auto;
  margin-right:auto;
  max-width: 800px;
  padding-top: 20px;
  .whitebox
  {
    margin-bottom: 120px;
    background-color: white;
    padding:40px;
    border-radius: $ft_box_radius;
    
    &.small-margin
    {
      margin-bottom: 30px;
    }

    
  }
}

.promo-code
{
  font-size: 18px;
  span.code 
  {
    background-color: yellow;
    padding:5px;
    margin-right: 10px;
  }
}

.mr5
{
  margin-right: 5px;
}

.whitebox
{
  margin-bottom: 120px;
  background-color: white;
  padding:20px;
  border-radius: $ft_box_radius;
  
  &.small
  {
    margin-bottom: 30px;
  }

  &.big-font
  {
    font-size: 18px;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  
}

.marktext
{
  line-height: 1.8;
  font-size: 16px;
  word-wrap: break-word;
  text-align:justify;
    
  strong
  {
      font-weight: normal;
      background-color: #fdf6e3;
      padding:3px;
      margin-left:2px;
      margin-right:2px;
  }
  .markimg
  {
    display: block;
    border-radius: $ft_box_radius;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    // max-width: calc( 100% - 20px )!important;
  }

  h1,h2,h3,h4,h5
  {
    margin-top: 20px;
    margin-bottom: 0px;
    
  }

  ul,ol
  {
      padding:20px;
      li
      {
          margin-left:0px;
          list-style: inside lower-roman;
      }        
  }

  ul li 
  {
      list-style: none;

      &::before
      {
          // content:'◎○♤☆§※';
          content:'🎈';
          margin-right: 5px;
      }
  }

  blockquote
  {
    border-left: 3px solid $ft_blue;
    margin-left: 0px;
    padding-left:10px;    }

  a
  {
      color:#0866a0;
      text-decoration: none;
      &:hover
      {
          padding-bottom: 3px;
          border-bottom: 1px solid #0866a0;
      }
  }



  pre>code
  {
    font-size: 14px;
    font-family: SourceCode pro,Courier, Monaco, monospace; ;
  }

}

// 通用css

.level1
{
  box-shadow: 0 2px 4px rgba(0,0,0,.16);
  transition: all 0.5s;
  &:hover
  {
    box-shadow: 0 8px 16px rgba(0,0,0,.16);
    // margin-left: 5px;
    transform:scale(1.02);
    transition: all 0.5s;
  }
}

.pointer
{
  cursor: pointer;
}

.flex
{
  display: flex;
}

.flexcenter
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.hcenter
{
  justify-content: center;
}

.vcenter
{
  align-items: center;
}

.fullcenter
{
  min-height: 100vh;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container
{
  max-width: 1000px;
  margin-left: auto;  
  margin-right: auto;  
}

.left-5
{
  margin-left: 5px;
}

.top-10
{
  margin-top: 10px;
}

.outline.bp3-button
{
  border: 1px solid $gray5;
}

.white.bp3-button
{
  .bp3-button-text
  {
    color:white;
  }
  svg
  {
    color:white;
  }
}

*:focus
{
    outline: none;
}

.std-hr
{
    border-top:1px solid $ft_light_gray;
    margin-top: 20px;
    padding-bottom: 20px;
}

.course-detail-box
{
  margin-top: 30px;
  .bp3-tab-list > *
  {
    font-size: $ft_title2;
  }
  
}

.subscribe-line
{
  background-color: white;
  border-radius: 10px;
  padding:30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > *
  {
    margin-right: 10px;
  }
  .left
  {
    width: 160px;
  }
  .center
  {
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    span
    {
      font-size: 16px;
      color: $ft_blue;
    }
  }
  .right
  {
    > *
    {
      margin-right: 10px;
    }
    > span
    {
      font-size: 18px;
      color: $ft_blue;
    }
  }
}

.subcribe-explain
{
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  padding:30px;
  font-size: 20px;
  line-height: 1.6;
  h3
  {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  ol,ul
  {
    margin-top: 5px;
    font-size: 18px;
  }
  .small
  {
    font-size: 18px;
    color:#AAAAAA;
    line-height: 1;
  }
}

.listinbox
{
  margin-top: 5px;
  font-size: 18px;
  line-height: 1.8;
  li
  {

  }
}

.userbox
{
  color:$ft_gray;
}

.row-half
{
    display: flex;
    flex-direction: row;
    >*
    {
        display: flex;
        flex:1;
        &:last-child
        {
          justify-content: flex-end;
          padding-left:5px;
        }
        &:not(:last-child)
        {
          padding-right:5px;
        }
        
    }
}

@media (max-width: 600px) 
{
    .course-box .content-box .container
    {
      display: flex;
      flex-direction: column;
      & > *
      {
        padding:20px;
      }
      .right
      {
        width:100%;
        .author-info
        {
          padding:40px;
        }
      }      
    }
    .course-index 
    {
      .chapter .title,.section-list > li
      {
        border-radius: 0px;
      }
      
    }


  

  
    .hero-box, .hero-box img
    {
      border-radius: 0px!important;
    }

    .ft-top-nav .logo
    {
      margin-left:10px;
      img
      {
        max-height: 40px;
      }
    }

    .rs-title
    {
      margin-left: 10px;
      margin-right: 10px;
    }

    
    .ft-hero-div
    {
      margin-top: 60px;
      margin-bottom: 0px;
    }

    .subscribe-list
    {
      .container
      {
        // background-color: bisque;
        flex-direction: column-reverse!important;
        align-items: center;
        .subscribe-card
        {
          margin-right: 0px!important;
          &:not(:first-child)
          {
            margin-bottom: 20px;
          }
        }
      }    
    }


    
    .works-list
    {
      .container
      {
        align-items: center!important;
        display: flex;
        flex-direction: column!important;
      }
    }
    
    .works-item
    {
      flex-direction: column!important;
      height:auto!important;
      margin:10px;
      width:300px!important;
      // max-width: calc( 100% - 20px );
      
      .screensnap
      {
        min-height: 300px;
        border-top-left-radius: 5px!important;
        border-bottom-left-radius: 0px!important;
        border-top-right-radius: 5px!important;
        border-bottom-right-radius: 0px!important;
        
        max-width: 100%;

        &.timetodo:hover .app
        {
          transform:scale(1.0);
        }
            
      }
      .desp
      {
        border-top-left-radius: 0px!important;
        border-bottom-left-radius: 5px!important;
        border-top-right-radius: 0px!important;
        border-bottom-right-radius: 5px!important;

        max-width: 100%;

        

        .actionbar
        {
          height: auto!important;
          position: relative!important;
          bottom: auto!important;
          > *
          {
            &:not(:last-child)
            {
              margin-right: 10px!important;
            }
            margin-bottom: 10px!important;
          }
          
        }
      
      }

    }

    .subcribe-explain
    {
      border-radius: 0px;
    }

    .subscribe-line
    {
      //margin-top: 20px;
      border-radius: 0px;
      flex-direction: column;
      align-items: center;
      > *
      {
        margin-bottom: 20px;
      }

      .right
      {
        display: flex;
        flex-direction: column;
        > *
        {
          margin-bottom: 20px;
        }
      }
    }
}

.p-60
{
  padding:160px;
  font-size: 24px;
}

  